@media screen and (max-width: 1920px) {
  .text-26 {
    font-size: 20px;
    line-height: 28px;
  }



  .text-24 {
    font-size: 22px;
    line-height: 28px;
  }

  .sidebar-wrapper {
    width: 255px;
  }

  main.main-content-wrapper .page-body {
    margin-left: 258px;
  }

  .logo-wrapper a.logo img {
    width: 150px;
    height: 40px;
    margin-top: 30px;
    margin-left: -10px;
  }

  .sidebar-menu {
    padding-top: 90px;
  }

  .about-detail-card .about-detail-left {
    width: 120px;
  }

  ul.sidebar-links .sidebar-link {
    font-size: 18px;
    line-height: 22px;
  }

  ul.sidebar-links .sidebar-link i {
    font-size: 20px;
    line-height: 20px;
  }

  .primary-bttn {
    padding: 7px 14px;
  }

  .refresh-btn {
    margin-right: 3rem;
  }

  .event-management-btn-row .refresh-btn {
    margin-right: 0px;
  }

  .container-fluid .about-detail-card .about-detail-right h3 {
    font-size: 30px;
    line-height: 35px;
  }

  .recent-profile-wrap h2 {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 20px;
    width: 190px;
  }

  .dash-top-event .text-darkgrey {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .refresh-icon {
    position: absolute;
    left: 10px;
    top: 9px;
    width: 16px;
  }

}

@media screen and (max-width: 1600px) {



  .text-26 {
    font-size: 20px;
    line-height: 28px;
  }

  .text-24 {
    font-size: 22px;
    line-height: 28px;
  }

  .sidebar-wrapper {
    width: 258px;
  }

  main.main-content-wrapper .page-body {
    margin-left: 258px;
  }

  .logo-wrapper a.logo img {
    width: 145px;
    height: 40px;
    margin-top: 20px;
    margin-left: -10px;
  }

  .sidebar-menu {
    padding-top: 90px;
  }

  ul.sidebar-links .sidebar-link {
    font-size: 16px;
    line-height: 22px;
  }

  ul.sidebar-links .sidebar-link i {
    font-size: 20px;
    line-height: 20px;
  }

  .primary-bttn {
    padding: 7px 14px;
  }

  .refresh-btn {
    margin-right: 0rem !important;
  }

  .recent-profile-wrap h2 {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 16px;
    width: 155px;
  }

  .dash-top-event .text-darkgrey {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .refresh-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 14px;
  }
}

@media screen and (max-width: 1400px) {

  h1,
  .h1 {
    font-size: 20px;
    line-height: 26px;
  }

  h2,
  .h2 {
    font-size: 14px;
    line-height: 20px;
  }

  .recent-profile-wrap a.table-search {
    font-size: 12px;
    line-height: 18px;
  }

  .logo img {
    width: 130px;
    height: 40px;
    margin-top: 20px;
    margin-left: -5px;
  }

  .sidebar-menu {
    padding-top: 80px;
  }

  ul.sidebar-links .sidebar-link {
    font-size: 16px;
    line-height: 20px;
  }

  ul.sidebar-links .sidebar-link i {
    font-size: 18px;
    line-height: 18px;
    margin-right: 15px;
  }

  ul.sidebar-links li.sidebar-list+li.sidebar-list {
    margin-top: 20px;
  }

  .sidebar-wrapper {
    width: 238px;
  }

  main.main-content-wrapper .page-body {
    margin-left: 238px;
  }

  .recent-profile-wrap h2 {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 16px;
    width: 170px;
  }

  .primary-bttn {
    font-size: 10px;
    line-height: 16px;
  }

  .social-media-update span.social-media-update-time {
    width: 65px;
  }

  .container-fluid .about-detail-card .about-detail-right h3 {
    font-size: 26px;
    line-height: 30px;
  }

  .dash-top-event .text-darkgrey {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .refresh-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 14px;
  }


}

@media screen and (max-width: 1199px) {

  .text-26 {
    font-size: 18px;
    line-height: 26px;
  }

  .text-24 {
    font-size: 20px;
    line-height: 26px;
  }

  .container-fluid .about-detail-card .about-detail-right h3 {
    font-size: 22px;
    line-height: 30px;
  }

  .col-lg-3 .about-detail-card .about-detail-left img {
    width: 35px;
    height: 35px;
  }

  .dash-top-event .text-darkgrey {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 991px) {
  li.profile-wrapper {
    margin-left: 10px;
    padding-left: 10px;
  }

  ul.header-top-right-menus li:nth-child(4) {
    margin-right: 0;
  }

  .profile-box .profile-name-rights {
    margin-left: 10px;
    margin-right: 15px;
  }

  .logo-wrapper a.logo img {
    margin-top: 15px;
  }

  .page-body-wrapper.toggleNav img.logo-toggle {
    display: block;
    margin-right: 0;
    width: 45px;
    height: 35px;
    margin-left: -10px;
  }

  .logo-wrapper a.logo img {
    width: 120px;
    height: 35px;
    margin-top: 20px;
    margin-left: -10px;
  }
}

@media (min-width: 771px) and (max-width: 950px) {
  .card-uploadfile {
    max-width: 41% !important;
  }
}

@media only screen and (max-width: 768px) {

  h1,
  .h1 {
    font-size: 18px;
    line-height: 24px;
  }

  main.main-content-wrapper .page-body {
    margin-left: 0;
    padding: 0 !important;
    padding-bottom: 30px;
  }

  header.head-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #ffffff;
    box-shadow: 0px 6px 6px #00000029;
  }

  .profile-wrapper {
    padding-left: 0px !important;
  }

  a.icon-three-dots-vertical-header {
    color: #889cb2;
    font-size: 16px;
    line-height: 16px;
    width: 16px;
    height: 16px;
    text-align: center;
    z-index: 9999;
  }

  .page-body-wrapper.toggleNav .page-body {
    margin-left: 0 !important;
  }

  .sidebar-wrapper {
    z-index: 999;
    left: 0%;
  }

  .page-body-wrapper.toggleNav .sidebar-wrapper {
    left: -100%;
  }

  .sidebar-wrapper .logo-wrapper {
    display: none;
  }

  .dashboard-page.rightbar-menu .icon-three-dots-vertical-header i {
    transform: rotate(45deg);
  }

  .dashboard-page.rightbar-menu .icon-three-dots-vertical-header i::before {
    content: "\e911";
  }

  ul.header-right-bar {
    position: fixed;
    right: -100%;
    width: 100%;
    background-color: #ffffff;
    z-index: 99;
    padding: 0;
    margin: 0;
    list-style: none;
    top: 0;
    transition: 0.5s all ease-in-out;
  }

  .dashboard-page.rightbar-menu ul.header-right-bar {
    right: 0;
  }

  ul.header-right-bar>li {
    max-width: calc(100% - 50px);
    margin: 20px 15px;
    position: relative;
  }

  ul.header-right-bar>li>a {
    font-size: 20px;
    line-height: 26px;
    color: #889cb2;
    display: flex;
    align-items: center;
  }

  ul.header-right-bar>li>a.lang-box>img {
    width: 20px;
    height: 20px;
  }

  ul.header-right-bar>li:not(:first-child) a {
    margin-left: 5px;
  }

  ul.header-right-bar>li>a>span {
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
  }

  ul.header-right-bar>li>ul.submenu-nav {
    display: none;
  }

  ul.header-right-bar>li.active>ul.submenu-nav {
    display: block;
  }

  ul.submenu-nav a {
    font-size: 12px;
    line-height: 12px;
    color: #889cb2;
  }

  ul.submenu-nav>li {
    padding-left: 40px;
    margin: 15px 0;
  }

  ul.submenu-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  body.dashboard-page span.one {
    -webkit-transform: rotate(45deg) translate(3px, 3px);
    -ms-transform: rotate(45deg) translate(3px, 3px);
    transform: rotate(45deg) translate(1px, 1px);
  }

  body.dashboard-page span.three {
    -webkit-transform: rotate(-45deg) translate(3px, -1px);
    -ms-transform: rotate(-45deg) translate(3px, -1px);
    transform: rotate(-45deg) translate(0px, -2px);
  }

  body.dashboard-page span.two {
    opacity: 0;
  }

  body.dashboard-page.toggleClass span.one,
  body.dashboard-page.toggleClass span.three,
  body.dashboard-page.toggleClass span.two {
    -webkit-transform: rotate(0) translate(0);
    -ms-transform: rotate(0) translate(0);
    transform: rotate(0) translate(0);
    opacity: 1;
  }

  .page-body-wrapper .sidebar-wrapper {
    width: 300px !important;
    padding: 60px 20px;
  }

  .sidebar-menu ul.sidebar-links .sidebar-link {
    font-size: 16px;
    line-height: 19px;
  }

  .page-title .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .user-header-img {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .page-title .dropdown .dropdown-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .mobile-navigation {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .dropdown-item {
    font-size: 12px;
    text-align: center;
    padding: 7px;
  }

  .participic-logo {
    width: 140px;
    height: 35px;
  }
.appstore-logo {
  width: 140px;
  height: 52px;
}
  main.main-content-wrapper .page-body {
    margin-left: 0px !important;
  }

  .download-btn {
    width: 100% !important;
    font-size: 12px;
  }

  .delete-all-photo {
    width: 100% !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .Create-event-save-btn {
    width: 100% !important;
  }


  .mobilebuttons {
    padding-bottom: 10px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .card-uploadfile {
    max-width: 44%;
  }

  .mobile-navigation .toggle-sidebar {
    position: absolute;
    top: 25px;
    left: 30px;
    cursor: pointer;
    padding: 0px;
  }

  .sidebar-wrapper .toggle-sidebar {
    position: absolute;
    top: 25px;
    left: 20px;
    cursor: pointer;
    padding: 0px;
  }

  .sidebar-menu {
    padding-top: 10px;
  }

  .file-loading input {
    width: auto;
  }

  .container-fluid {
    padding-bottom: 50px;
  }

  .recent-profile-wrap h2 {
    padding-right: 10px;
    margin-right: 10px;
    font-size: 14px;
    line-height: 17px;
    width: 180px;
  }

  .mobile-m {
    margin-bottom: 20px;
    justify-content: flex-end;
  }

  .user-table table {
    overflow-x: scroll;
    display: table;
  }

  .dashboard .dash-top-event {
    min-height: auto;
  }

  .about-detail-card {
    height: 110px;
  }

  .has-search .form-control-feedback {
    top: 10px;
    left: 10px;
    width: 15px;
  }

  input:focus {
    font-size: 13px;
    /* adjust the font size as needed */
  }


  .form-control:focus {
    font-size: 13px !important;
  }

  .ql-snow .ql-editor h2 {
    height: 155px;
  }




  .mobileexport {
    justify-content: end;
    display: flex;
    margin-bottom: 30px;
  }

  .event-management-btn-row .createeventmng {
    justify-content: center;
  }
}


@media only screen and (max-width: 575px) {
  .text-16 {
    font-size: 14px;
    line-height: 20px;
  }

  .text-12 {
    font-size: 12px;
    line-height: 15px;
  }

  .login-form-wrapper {
    max-width: 320px;
  }

  .login-form-wrapper .login-form-content {
    padding: 25px 20px !important;
  }

  form.login-form,
  .login-form-wrapper .login-form-content h2 {
    margin-bottom: 10px;
  }

  a.new-mbrshp-link {
    margin-top: 12px;
  }

  a.logo img {
    max-width: 200px;
  }

  .primary-bttn {
    font-size: 10px;
    line-height: 16px;
  }

  a.primary-bttn.primary-left-icon i {
    margin-right: 5px;
  }

  a.primary-bttn.primary-right-icon i {
    margin-left: 5px;
  }

  .bttn-group-space {
    justify-content: space-between;
    max-width: 330px;
  }

  .bttn-group-space a {
    margin: 0;
  }

  .Recent-event {
    flex: 0 0 100%;
  }

  .refresh-btn-wrapper {
    flex: 0 0 30%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .user-table table {
    overflow-x: scroll;
    display: block;
  }
}

/* Small devices (landscape phones, 320px and up) */
@media (min-width: 320px) {
  .tabs-navigation {
    display: none;
  }

  .logo-fit {
    max-width: auto !important;
    /* margin-bottom: auto !important; */
  }

  .login-form-wrapper .login-form-content h2 {
    margin-bottom: 10px !important;
  }

  .login-form-wrapper .login-form-content {
    padding: 0px;
  }

  /***Create Event***/
  .page-body .create-event-wrapper .create-event-details .mobile-heading {
    font-size: 16px;
  }

  .dashboard-header-nav .buttons-main {
    display: none !important;
  }

  .dashboard-header-nav .buttons-justify {
    justify-content: space-between !important;
  }

  .page-body .main-buttons-tab {
    display: none;
  }

  .page-body .page-title .main-title {
    padding-top: 10px;
  }

  .nav-event-bar .nav-item:first-child {
    width: 324px;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
  }

  .mobile-report {
    display: block;
  }

  .report-download {
    border-left: none;
    display: flex;
    align-items: center;
    padding-top: 20px;
  }

  .report-download span {
    padding-right: 10px;
  }

  .buttons-bottom button {
    margin-top: 10px;
  }

  .small-modal-conf {
    width: 84vw;
  }

}

/*****Tabs*****/
@media (max-width: 1024px) and (min-width: 769px) {
 

  .each-slide.each-pop-slider img {
    height: auto;
    width: 100%;

  }

  .sidebar-wrapper {
    width: 210px;
    padding: 0px 20px;
  }

  .toggle-sidebar {
    top: 90px;
    left: 20px;
  }

  .page-title h1 {
    font-size: 18px;
    line-height: 21px;
  }

  main.main-content-wrapper .page-body {
    margin-left: 210px;
  }

  .page-body-wrapper.toggleNav .sidebar-wrapper {
    padding: 0px 20px;
  }

  .col-lg-3 .about-detail-card .about-detail-left {
    width: 90px;
  }

  .about-detail-card .about-detail-left img {
    width: 40px;
    height: 40px;
  }

  ul.sidebar-links .sidebar-link {
    font-size: 15px;
  }

  .dropdown .user-header-img {
    height: 35px;
    width: 35px;
  }

  .page-title .dropdown .dropdown-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .my-profile-user-info.profile {
    padding: 10px;
  }

  .recent-profile-wrap h2 {
    font-size: 14px;
    width: 170px;
  }

  .page-body-wrapper.toggleNav .page-body {
    margin-left: 70px;
  }

  main.main-content-wrapper .page-body {
    padding: 0px;
  }

  .mobile-navigation {
    display: none;
  }

  .tabs-navigation {
    display: flex;
  }

  .page-title .header-top-right-menus {
    display: none;
  }

  .dashboard-header-nav {
    padding-top: 10px;
  }

  .page-body .dash-top-event {
    margin-bottom: 30px;
  }

  .dashboard-card-outline .user-img-tab {
    height: 25px;
    width: 25px;
  }

  .recent-event-text li {
    font-size: 9px;
  }

  .page-body .tab-card {
    padding: 10px;
  }

  .page-body .tab-card .dash-card-follow {
    font-size: 9px;
    line-height: 10px;
  }

  .page-body .tab-card .padd-tab {
    padding: 10px 0px !important;
  }

  .dashboard-header-nav .buttons-main {
    display: flex !important;
  }

  .page-body .main-buttons-tab {
    display: none;
  }

  .nav-event-bar .nav-item:first-child {
    width: 200px;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .mobile-report {
    display: flex;
    align-items: center;
  }

  .page-body .create-event-wrapper .create-event-details .mobile-heading {
    font-size: 20px;
  }

  .buttons-bottom {
    display: flex;
    justify-content: space-between;
  }

  .small-modal-conf {
    width: 35vw;
  }

  .profile-modal {
    width: 35vw;
  }


  .recent-profile-wrap .recent-profile-wrap h2 {
    font-size: 14px;
    line-height: 20px;
    margin-right: 5px;
  }

  .event-table .col-lg-3,
  .event-table .col-md- {
    padding-left: 0px;
  }

  .refresh-icon {
    position: absolute;
    left: 8px;
    top: 10px;
    width: 14px;
  }

  .dashboard .dash-top-event {
    min-height: auto;
    max-height: 780px;
  }

  .dash-top-event .text-darkgrey {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

  .sidebar-wrapper {
    display: block;
  }

  .logo-fit {
    max-width: 100% !important;
    /* margin-bottom: 4rem !important; */
  }

  .login-form-wrapper .login-form-content h2 {
    margin-bottom: 10px !important;
  }

  .page-body-wrapper.toggleNav .page-body {
    margin-left: 70px;
  }

  main.main-content-wrapper .page-body {
    padding-top: 20px;
  }

  .mobile-navigation {
    display: none;
  }

  .tabs-navigation {
    display: none;
  }

  .page-title .header-top-right-menus {
    display: flex;
  }

  .dashboard-header-nav {
    padding-top: 0px;
  }

  .dashboard-card-outline .user-img-tab {
    height: 35px;
    width: 35px;
  }

  .recent-event-text li {
    font-size: 12px;
  }

  .page-body .dashboard-header-nav .buttons-main {
    display: flex !important;
    justify-content: end !important;
  }

  .nav-event-bar .nav-item:first-child {
    width: 200px;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .dashboard-header-nav .buttons-justify {
    display: none !important;
  }

  .page-body .main-buttons-tab {
    display: block;
  }

  .mobile-report {
    display: flex;
    align-items: center;
  }

  .report-download {
    display: block;
    padding-top: 0px;
  }

  .report-download .download-icons {
    padding-top: 10px;
  }

  .page-body .create-event-wrapper .create-event-details .mobile-heading {
    font-size: 20px;
  }

  .buttons-bottom {
    display: flex;
    justify-content: space-between;
  }

  .small-modal-conf {
    width: 19vw;
  }

  .user-modal-conf {
    width: 25vw;
  }

  .profile-modal {
    width: 25vw;
  }

}


.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
margin-bottom: 10px;
 font-size: 18px;
  text-align: center;
}