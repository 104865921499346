@charset "utf-8";

html {
  touch-action: pan-y;
  zoom: reset;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-action: pan-y;
  -ms-scroll-limit-y-maximum: 1px;
  -ms-scroll-limit-y-minimum: 1px;
}

.alertMessage {
  flex: 0 0 50%;
  max-width: 100%;
}

input.largerCheckbox {
  width: 20px;
  height: 20px;
  display: block
}

.alert-new {

  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

}

.alert-danger-new {
  color: #aa0909;
  background-color: #ffffff;
}

.alert-success-new {
  color: #077446;
  background-color: #ffffff;
}

body {

  position: relative;
  margin: 0px;
  padding: 0px;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: normal;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  height: 100%;
  color: #595959 !important;
}

.text-26 {
  font-size: 26px !important;
}

.text-14 {
  font-size: 14px !important;
}

.text-15 {
  font-size: 15px !important;
}

.text-19 {
  font-size: 19px !important;
}

.text-black {
  color: #000000 !important;
}

.mid-container {
  max-width: 838px;
  margin: 0 auto;
}

.privacy-policy-start {
  padding: 50px 10px 100px;
}

.privacy-policy-start p {
  line-height: 25px;
  font-size: 14px;
}

.table-of-content-links a {
  display: block;
  font-size: 14px;
  margin-bottom: 3px;
}

.Demo__clear-button,

.Demo__clear-button:active,

.Demo__clear-button:focus {

  position: absolute;

  right: 16px;

  top: 60%;

  transform: translateY(-50%);

  background: transparent;

  border: none;

  outline: none;

  font-weight: 600;

  color: #999;

}

.close-icon {
  border: 1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
  position: absolute;
  top: 41px;
  right: 20px;

}

.close-icon:after {
  content: "X";
  width: 15px;
  height: 15px;
  background-color: #4074e6;
  z-index: 1;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: normal;
  font-size: 10px;
  cursor: pointer;
  padding: 0px 4px;
  position: absolute;
  right: 4px;
}



.autocomplete-dropdown-container {

  border-bottom: honeydew;

  border-left: honeydew;

  border-right: honeydew;

  border-top: 1px solid #e6e6e6;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  position: absolute;

  width: 95%;

  z-index: 1000;

  border-radius: 0 0 2px 2px;

}

.suggestion-item {

  padding: 12px;

  text-align: left;

  background-color: #fff;

  cursor: pointer;
  border-radius: 10px;
}



.suggestion-item--active {

  background-color: #4074e6;
  color: #fff;
  text-align: left;
  cursor: pointer;
  padding: 12px;


}


h1 {
  font-size: 21px;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #fc9005;
  font-weight: 600;
}

h2,
.h2 {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
}

/*font family*/
.font-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.font-regular {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.font-light {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.font-semibold {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

/*text classes*/

.text-26 {
  font-size: 26px;
  line-height: 32px;
}

.text-24 {
  font-size: 24px;
  line-height: 30px;
}

.text-22 {
  font-size: 22px;
  line-height: 28px;
}

.text-20 {
  font-size: 20px;
  line-height: 26px;
}

.text-18 {
  font-size: 18px;
  line-height: 24px;
}

.text-16 {
  font-size: 16px;
  line-height: 22px;
}

.text-14 {
  font-size: 14px;
  line-height: 24px;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}

.text-13 {
  font-size: 13px;
  line-height: 18px;
}

.text-11 {
  font-size: 11px !important;
  line-height: 16px;
}

.text-8 {
  font-size: 8px;
  line-height: 14px;
}

.text-button-grey {
  color: #4b4d4f;
}

.text-black {
  color: #201d19;
}

.text-darkgrey {
  color: #19171a;
}

.text-lightgrey {
  color: #b0b0b0;
}

.text-grey {
  color: #0a0a0a;
}

.text-blue {
  color: #1a79ff;
}

.text-navyblue {
  color: #7191be;
}

.text-darkblue {
  color: #374252;
}

.text-white {
  color: #ffffff;
}

.text-greenblue {
  color: #13b4b3;
}

.bg-lightblue {
  background-color: #dee5f5;
}

.bg-blue {
  background-color: #1a79ff;
}

.bg-greenblue {
  background-color: #13b4b3;
}

.bg-lightgreenblue {
  background-color: #56d8a4;
}

.bg-purple {
  background-color: #7331d0;
}

.bg-lightgrey {
  background-color: #aabacc;
}

/*-- Login Page --*/
main.main-content {
  background-image: url("../images/Login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}

.login-page main.main-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* padding: 0 20px; */
}

.login-form-wrapper {
  max-width: 620px;
  width: 100%;
  text-align: center;
  margin: 50px 0;
}

.login-form-wrapper .login-form-head {
  background-color: #f6e7c9;
  padding: 17px 10px;
  /* border-top-left-radius: 8px;
    border-top-right-radius: 8px; */
}

.login-form-wrapper .login-form-content {
  background-color: #f6e7c9;
  padding: 40px 100px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.login-form-wrapper .login-form-content h2 {
  margin-bottom: 40px;
}

.form-group-design select.form-control-new {
  padding: 5px 0;
  height: 35px;
  width: 100%;
  border: 0;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid #d9d4c2;
  font-size: 16px;
  line-height: 22px;
  color: #19171a;
  font-family: 'Open Sans', sans-serif;
  transition: 0.3s all ease-in-out;
}

.form-group-design select.form-control-new:focus {
  border-bottom-color: #9ca2b1;
}

.form-group-design input.form-input {
  padding: 3px 0;
  height: auto;
  width: 100%;
  border: 0;
  outline: none;
  background-color: transparent !important;
  border-bottom: 2px solid #d9d4c2;
  font-size: 16px;
  line-height: 22px;
  color: #19171a;
  font-family: 'Open Sans', sans-serif;
  transition: 0.3s all ease-in-out;
}

.form-group-design input.form-input:focus {
  border-bottom-color: #9ca2b1;
}

.form-group-design label {
  margin-bottom: 0;
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
}

a.forgot-psw-link,
a.new-mbrshp-link {
  display: block;
  text-decoration: none;
}

.form-group-design input.form-input[type="email"] {
  background-image: url(../images/envelope.svg);
  background-repeat: no-repeat;
  background-position: center right 2px;
}

.form-group-design input.form-input[type="password"] {
  background-image: url(../images/key.svg);
  background-repeat: no-repeat;
  background-position: center right;
}

.form-group-design {
  margin-bottom: 27px;
}

form.login-form {
  margin-bottom: 80px;
}

a.new-mbrshp-link {
  margin-top: 20px;
}

p.copyright-content {
  margin-top: 20px;
}

.login-form-radius {
  border-radius: 8px;
  /* box-shadow: 0px 1px 22px #BECDFF; */
}

.btn-sign-in {
  background-color: #fc9005 !important;
  border: none;
  width: 320px;
  line-height: 40px;
  border-radius: 25px;
  max-width: 100%;
}

.btn-loadmore {
  background-color: #fc9005 !important;
  border: none;
  width: 150px;
  line-height: 30px;
  border-radius: 25px;
  max-width: 100%;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
}

.logo-fit {
  max-width: 100%;
  height: 150px;
  display: block;
  margin: 0 auto;
  /* margin-bottom: 4rem; */
}

/*-- Dashboard --*/
.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  height: 100%;
  padding: 0 20px;
  line-height: inherit;
  background: #242e3d;
  width: 282px;
  text-align: left;
  transition: all 0s ease-out;
  padding-bottom: 20px;
  /* overflow-y: auto; */
  animation: 3s ease 6;
}

.sidebar-wrapper .mobile-navigation {
  display: flex;
  position: relative;
  justify-content: center;
}


.logo-wrapper a.logo {
  padding: 20px 0;
  width: 100%;
}

.toggle-sidebar span {
  width: 20px;
  height: 2px;
  background-color: #889cb2;
  display: block;
  transform-origin: 4px 0px;
  position: relative;
}

.toggle-icon {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.toggle-sidebar span:nth-child(2) {
  margin: 4px 0;
}

.toggle-sidebar {
  position: absolute;
  top: 100px;
  left: 20px;
  cursor: pointer;
  padding: 0px;
}


ul.sidebar-links {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: calc(100vh - 170px);
}

ul.sidebar-links .sidebar-link {
  font-size: 18px;
  line-height: 24px;
  color: #889cb2;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  align-items: center;
  position: relative;
  transition: none;
  font-weight: 600;
}

ul.sidebar-links .active .sidebar-link,
ul.sidebar-links .sidebar-link {
  height: 30px;
}

ul.sidebar-links .active .sidebar-link,
ul.sidebar-links .sidebar-link:hover {
  color: #fc9005;
  text-decoration: none;
  height: 30px;
  filter: invert(54%) sepia(85%) saturate(1697%) hue-rotate(1deg) brightness(104%) contrast(98%);
}

.sidebar-menu ul.sidebar-links li.sidebar-list:last-child {
  position: absolute;
  bottom: 25px;
}

ul.sidebar-links li.sidebar-list+li.sidebar-list {
  margin-top: 30px;
}

ul.sidebar-links .sidebar-link.toggle-navigation-icon i {
  font-size: 4px;
  line-height: 4px;
  padding: 10px 0;
}

ul.sidebar-links .sidebar-link:before {
  content: "";
  height: 100%;
  width: 0;
  background-color: transparent;
  position: absolute;
  left: -20px;
  border-radius: 20%;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

ul.sidebar-links .active .sidebar-link:before,
ul.sidebar-links .sidebar-link:hover::before {
  background-color: #1a79ff;
  width: 5px;
}

main.main-content-wrapper .page-body {
  background-color: #f5f6fa;
  min-height: 100vh;
  margin-left: 282px;
  position: relative;
  /* -webkit-transition: all 0.3s ease; */
  /* transition: all 0.3s ease; */
  padding: 20px 15px 30px 15px;
}

ul.header-top-right-menus {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notification-box.dropdown-toggle::after {
  display: none;
}

.profile-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  position: relative;
}

.profile-box .profile-name-rights {
  color: orange;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  margin-left: 7px;
  margin-right: 10px;
}

.profile-box .profile-name-rights b {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

li.profile-wrapper {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 2px solid #e2e2e2;
}

ul.header-top-right-menus li:not(:last-child) {
  margin-right: 20px;
}

ul.header-top-right-menus li:not(:last-child) i {
  font-size: 20px;
  line-height: 26px;
  color: #808080;
  cursor: pointer;
}

ul.header-top-right-menus li .lang-box img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.square-box {
  width: 10px;
  height: 10px;
  border-radius: 1px;
  margin-right: 10px;
}

a.primary-bttn.primary-left-icon i {
  margin-right: 10px;
}

a.primary-bttn.primary-right-icon i {
  margin-left: 10px;
}

.event-gallry-table .table thead th {
  color: #889cb2;
  font-size: 12px;
  line-height: 18px;
  border-top: 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  padding: 12px;
  border-bottom: 2px solid #d1d1d1;
  white-space: nowrap;
}

.event-data .table td .table th {
  vertical-align: middle;
  padding: 0.25rem 0.25rem 0.25rem 0rem !important;
  line-height: 1rem !important;
  font-size: 12px !important;
}

.event-list {
  font-size: 11px !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.event-list td {
  padding: 6px 1rem !important;
  color: #212529 !important;
  font-weight: 400 !important;
  vertical-align: middle !important;
}

.event-list button {
  width: 2rem !important;
  height: 30px;
}

.event-list button span {
  font-size: 14px !important;
}

.dropdown-toggle::after {
  border-right: none !important;
  border-left: none !important;
}

.primary-bttn.font-bold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.primary-bttn.font-bold i {
  font-weight: bold;
}

header.head-navigation {
  display: none;
}

img.logo-toggle,
.page-body-wrapper.toggleNav li.sidebar-list span {
  display: none;
}

.page-body-wrapper.toggleNav img.logo-desktop {
  display: none;
}

.page-body-wrapper.toggleNav img.logo-toggle {
  display: block;
  margin-right: 0;
  width: 50px;
  height: 40px;
  margin-left: -10px;
}

.page-body-wrapper.toggleNav .sidebar-wrapper {
  width: 70px;
  padding: 0 20px;
}

.page-body-wrapper.toggleNav .page-body {
  margin-left: 70px;
}

.page-body-wrapper.toggleNav ul.sidebar-links .sidebar-link i {
  margin-right: 0;
}

ul.header-right-bar>li>a>i.icon-down-arrow {
  font-size: 8px;
  line-height: 8px;
  margin-left: 20px;
  transition: 0.3s all ease;
}

ul.header-right-bar>li.active>a>i.icon-down-arrow {
  transform: rotateZ(-180deg);
}

ul.header-right-bar>li>.lang-box+ul.submenu-nav a img {
  margin-right: 5px;
}

p.copyright-text {
  font-size: 11px;
  line-height: 18px;
  color: #333333;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

/* events gallary */

ul.nav-event-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dae2ee;
  color: #7191be !important;
  border-radius: 22px;
}

.nav-event-bar .nav-item:first-child {
  background: #dae2ee 0% 0% no-repeat padding-box;
  color: #7191be !important;
  width: 200px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

a#event-tab {
  color: #7191be !important;
}

.add-event-btn {
  position: relative;
  background: #fc9005;
  position: relative;
  color: white;
  border: none;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  line-height: 14px;
}

.refresh-btn {
  position: relative;
  background: #dee5f5;
  color: black;
  border: none;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  line-height: 18px;
}

.pi-sort-alt:before {
  font-size: 12px;
  content: "\e99e";
}

.event-btn {
  width: 100px;
  height: 30px;
  max-width: 100%;
}

/* .grid-view-btn {
  width: 20px;
  height: 20px;
  max-width: 100%;
}

.list-view-btn {
  width: 20px;
  height: 20px;
  max-width: 100%;
}*/
.vertical-line {
  border-right: 2px solid #e2e2e2;
  height: 1.5rem;
}

/* create event */
.event-management-btn-row .event-image {
  position: absolute;
  top: 10px;
  left: 10px;

}

.create-event-wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  opacity: 1;
  color: black;
}

.dropdown-menu-new.show {
  display: block;
  /* margin-left: 115px; */
  text-align: center;
  margin-top: 0px;
}


.drop-menu-width {
  min-width: 7rem !important;
}

.dropdown-menu-new {
  position: absolute;
  top: 0;
  right: 0 !important;
  z-index: 1000;
  display: none;
  float: left;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transform: translate3d(-10px, 38px, 0px);
  will-change: transofarm;
}

.code-change-btn {
  border-radius: 15px;
  color: #090909 !important;
  font-weight: 500 !important;
}

.code-change-btn:hover {
  border-radius: 15px;
  background-color: #dee5f5 !important;
}

.Create-event-cancle-btn {
  border-radius: 15px;
  width: 85px;
  height: 36px;
}

.p-button.Create-user-cancle-btn {
  font-size: 13px;
  line-height: 17px;
  border-radius: 15px;
  width: 100px;
  height: 30px;
  margin-left: 7px;

}

.Create-user-cancle-btn.user-info-cancel {
  font-size: 14px;
  line-height: 17px;
  border-radius: 15px;
  width: 100px;
  height: 30px;
  margin-left: 7px;
}

.delete-all-photo {
  border-radius: 15px;
  width: 160px;
  height: 36px;
}

.Create-event-save-btn {
  background: #fc9005;
  color: #fef3e5;
  border-radius: 15px;
  width: 148px;
  height: 36px;
  position: relative;
}

.create-event-wrapper .p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute;
  left: 10px;
  top: 10px;

}

.edit-profile .p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute;
  left: 10px;
  top: 8px;

}

.p-button.p-button-loading-label-only .p-button-loading-icon {
  position: absolute;
  left: 10px;
  top: 10px;

}


.Change-password-btn {
  background: #fc9005;
  color: #fef3e5;
  border-radius: 15px;
  width: 223px;
  height: 36px;
}

.myProfile-btn {
  background: #fc9005;
  color: #fef3e5;
  border-radius: 15px;
  width: 162px;
  height: 36px;
}

.download-link-csv {
  padding-right: inherit;
}

.create-event-details .heading-1 {
  border-right: 1px solid #9ca2b1;
  padding-right: 20px;
  margin-right: 20px;
  text-decoration-line: underline;
  text-decoration-color: #fc9005;
  text-decoration-thickness: 4px;
  text-decoration-style: solid;
}

.invisible-area {
  visibility: hidden;
}

/* form - placeholder css */
/* input[type="area"]::placeholder {
  color: #b0b0b0;
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
}

input[type="name"]::placeholder {
  color: #b0b0b0;
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
}

input[type="code"]::placeholder {
  color: #b0b0b0;
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
}

textarea::placeholder {
  color: #b0b0b0 !important;
  font-family: "OpenSans-SemiBold";
  font-size: 14px;
} */

select:required:invalid {
  color: #b0b0b0 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.form-control-new {
  color: #302f2f !important;
  display: block;
  width: 100%;
  height: 35px;
  padding: 5px 10px;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.form-control-new1 {
  color: #302f2f !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 5px 10px;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

/* .form-control-new::placeholder {
  color: #b0b0b0 !important;
}

.form-control-new::-webkit-input-placeholder {

  color: #b0b0b0;

} */

/* WebKit, Blink, Edge */

/* .form-control-new:-moz-placeholder {

  color: #b0b0b0;

} */

/* Mozilla Firefox 4 to 18 */

/* .form-control-new::-moz-placeholder {

  color: #b0b0b0;

} */

/* Mozilla Firefox 19+ */
/* 
.form-control-new:-ms-input-placeholder {

  color: #b0b0b0;

} */

/* Internet Explorer 10-11 */

/* .form-control-new::-ms-input-placeholder {

  color: #b0b0b0;

} */

/* Microsoft Edge */

.approved-photo {
  width: 500px;
  height: 250px;
  max-width: 100%;
}

.approved-video {
  width: 2000px;
  height: 955px;
  max-width: 100%;
}


.each-slide img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
  max-height: 700px;
  min-height: 300px;
  width: auto;
}

.status-header {
  position: absolute;
  top: 14px;
}

.status-label {
  color: white;
  font-size: 2em;
  padding: 12px;
}

.apporve-button {
  text-align: right;
}

.SearchButtonEventPage {
  margin-top: 10px;
}

.input-group-date {
  width: 100%;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

/* my profile */
.my-profile-user-info {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000F;
  border-radius: 9px;
  opacity: 1;
}

.my-profile-user-info.profile {
  text-align: center;
  padding-top: 30px;
  padding-left: 41px;
  padding-right: 41px;
  padding-bottom: 30px;
}

.my-profile-user-info.profile .profile-img {
  margin-top: 30px;
  margin-bottom: 12px;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.my-profile-user-info.profile .page-title {
  padding-bottom: 20px;
}

.my-profile-user-info.profile .sec-title {
  color: #19171A;
  line-height: 26px;
}

.my-profile-user-info.profile .sub-title {
  color: #625b5b;
  font-weight: 600;
  line-height: 26px;
  overflow-wrap: break-word;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.my-profile-user-info.profile p {
  line-height: 14px;
  padding-bottom: 13px;
}

.my-profile-user-info.profile button {
  margin-bottom: 10px;
}

.my-profile-user-info.profile button {
  margin-bottom: 10px;
}

.dash-card-follow {
  background: #fc9005;
  color: #fef3e5;
  border-radius: 15px;
  max-width: 100%;
}

.my-profile-user-info.profile {
  text-align: center !important;
}

.add-user-btn {
  background-color: rgb(252, 144, 5);
  color: white;
  border: none;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  line-height: 17px;
}

.edit-btn {
  position: relative;
  background-color: rgb(252, 144, 5);
  color: white;
  border: none;
  border-radius: 20px;
  width: 130px;
  font-size: 13px;
  height: 35px;
  line-height: 20px;
}

.edit-btn .pi-user-edit {
  position: absolute;
  left: 11px;
  top: 10px;
  font-size: 13px;
}

.dashboard-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 9px;
  opacity: 1;
}

.dashboard-card .card-img {
  position: relative;
}

.recent-event-text ul>li>p {
  border-left: 1px solid #e2e2e2;
  margin-left: 5px;
  padding-left: 5px;
}

.dashboard-card-outline {
  border-bottom: 2px solid #e6e6e6;
}

.text-orange {
  color: #fc9005;
}

.bg-lightblue {
  background-color: #dee5f5 !important;
}

.bd-highlight {
  display: inline-block !important;
}

.delete-image {
  display: inline-block !important;
  float: right;
}

.d-flex.align-items-center.bd-highlight.pt-2.pb-2 {
  display: block !important;
}

.outline-delete-button {
  border-bottom: 2px solid #e6e6e6;
}

.card-col {
  padding-right: 10px;
  padding-left: 10px;

}

/* p {
  color: rgb(255 255 255 / 0.6);
} */

.card-img.approved-img .title {
  position: absolute;
  top: 15px;
  left: 20px;
  bottom: auto;
  color: #fff;
}

.card-img.approved-img .edit-ico {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.event-image-div {
  width: 100%;
  height: 100%;
  background-color: black;
}

.event-image-height {
  height: 215px;
  width: 100%;
}

.important-info-start-bg-stick {
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  margin-top: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  justify-content: space-between;
  padding: 5px 9px;
  z-index: 1;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #242E3D
}

.important-info-start-bg-stick-Rejected {
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  margin-top: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 5px 9px;
  z-index: 1;
  height: 40px;
  background-color: #ff0000ba
}


.important-info-start-bg-stick-Approved {
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  margin-top: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  padding: 5px 9px;
  z-index: 1;
  height: 40px;
  background-color: #608903f8;

}


.photo-gallery-approve-icon {
  background-color: #fc9005;
  padding: 5px;
  border-radius: 23px;
  color: #fff;
  font-size: 16px;
  width: 28px;
  text-align: center;
  border: none !important;
}

.photo-gallery-approve-icon:hover {
  background-color: #0069d9;
}

.photo-gallery-download-button {
  background-color: #dee5f5;
  padding: 5px;
  border-radius: 23px;
  color: #000;
  font-size: 16px !important;
  width: 75px;
  text-align: center;
  border: none !important;
}

.photo-gallery-download-button:hover {
  background-color: #0069d9;
  color: #fff;
}

.MuiInputBase-input.MuiInput-input {
  color: #302f2f !important;
  display: block;
  width: 100%;
  height: calc(0.6em + 0.75rem + 2px) !important;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.MuiInput-underline::after {

  border-bottom: none !important;

}

.MuiInput-underline::before {
  border-bottom: none !important;
}

#search_input {
  color: #b0b0b0 !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #ced4da; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 22px;
}

/* 
.form-control{
  font-size: 12px !important;
  } */

.react-slideshow-container {
  box-shadow: 1px 7px 9px #00000070;
  border-radius: 20px;
}


.photo-slide {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #000;
  height: 100vh;
}

.photo-slide .slick-track {

  display: flex;
  justify-content: center;
  align-items: center;
}

.popupmain-slider .default-nav {
  display: none !important;
}

.myProfile-btn {
  margin-top: 20px;
}

.p-button-primary {
  background-color: #fc9005;
  border: 1px solid #fc9005;
  border-radius: 20px;
  padding: 5px 15px;
  /* font-size: 16px; */
  height: 30px;
  color: #fff;
  /* width: 148px;
  height: 36px; */
}

.p-button-primary .p-button-label {
  font-weight: 500;
}

.p-button-primary:focus {
  box-shadow: none;
}

.display-l {
  display: flex;
  align-items: center;
}

/* .p-datatable-wrapper .table{
  display: block !important;
  width: 100%;
  overflow-x: auto;
  }  */
@media (min-width: 320px) and (max-width:720px) {
  .display-l {
    display: block;
  }


  .map-height {
    height: 300px !important;
  }
}

@media (min-width: 320px) and (max-width:767px) {

  .important-info-start-bg-stick {
    height: 74px;
  }

  .important-info-start-bg-stick-Approved {
    height: 74px;
  }

  .event-image-height {

    height: 279px;

  }

  .edit-ico.position-absolute {
    top: 0px !important;
  }

  .important-info-start-bg-stick-Rejected {
    height: 40px;
  }

  .p-datatable .p-datatable-tbody>tr>td:last-child {

    display: flex;

  }
}

@media (min-width: 768px) and (max-width:1600px) {

  .important-info-start-bg-stick {
    height: 55px;

  }

  .important-info-start-bg-stick.bottom-header {
    display: flex;
    /* height: 46px; */
  }

  .edit-ico.position-absolute {
    top: 10px !important;
  }

  .important-info-start-bg-stick-Rejected {
    height: 55px;

  }

  .important-info-start-bg-stick-Rejected.bottom-header-rejected {
    display: flex;
    height: 46px;
  }

  .important-info-start-bg-stick-Approved {
    height: 55px;
    display: flex;
  }

  .important-info-start-bg-stick-Approved.bottom-header-Approved {
    display: flex;
    height: 46px;
  }
}

.map-height {
  height: 800px;
}

.edit-ico.position-absolute {
  top: 0px;
  right: 24px;
}



.edit-ico.like-btn {
  right: 0px;
}

/* .page-body-wrapper .para-width {
  width: 90px;
} */

.dropdown-map {
  border-radius: 10px;
  box-shadow: 0px 2px 6px #00000029;

}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-p0rm37 {
  font-size: 12px !important;
  line-height: 20px !important;
  transform: translate(14px, 7px) scale(1) !important;
}

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root,
.css-1na68kg .MuiOutlinedInput-root {
  padding: 0px !important;
}

.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr {
  line-height: 30px
}

.rdw-image-left {
  justify-content: center
}


@media (max-width:1024px) and (min-width: 768px) {
  .userevent-flex {
    display: block !important
  }
}

.p-datatable .p-datatable-tbody>tr>td {
  white-space: nowrap;
  cursor: pointer;
}

/* 
.app .ql-editor {
  font-family: "Roboto";
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto", cursive;;
    content: "Roboto" !important;
} */

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  ;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  ;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  ;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  ;
  content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  font-family: "Arial", cursive;
  ;
  content: "Arial" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before {
  font-family: "Helvetica", cursive;
  ;
  content: "Helvetica" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="sans-serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="sans-serif"]::before {
  font-family: "sans-serif", cursive;
  ;
  content: "sans-serif" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  font-family: "serif", cursive;
  ;
  content: "serif" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Monospace"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Monospace"]::before {
  font-family: "Monospace", cursive;
  ;
  content: "Monospace" !important;
}

/* Set content font-families */
/* .ql-font-Roboto {
  font-family: "Roboto"!important;
} */
.ql-font-Raleway {
  font-family: "Raleway" !important;
}

.ql-font-Montserrat {
  font-family: "Montserrat" !important;
}

.ql-font-Lato {
  font-family: "Lato" !important;
}

.ql-font-Rubik {
  font-family: "Rubik";
}

.ql-font-Arial {
  font-family: "Arial" !important;
}

.ql-font-sans-serif {
  font-family: "sans-serif" !important;
}

.ql-font-Helvetica {
  font-family: "Helvetica" !important;
}

.ql-font-serif {
  font-family: "serif" !important;
}

.ql-font-serif {
  font-family: "Monospace" !important;
}

.ql-snow .ql-editor h1 {
  text-align: center;
}

.ql-editor p img {
  text-align: center;
}

.ql-snow .ql-editor h5 {
  text-align: center;
}

.ql-snow .ql-editor h2 {
  text-align: center;
}

.ql-snow .ql-editor h1 span {
  text-align: center;
}

.fullscreen-btn {
  z-index: 999 !important;
}

.close {
  float: right !important;
  position: absolute;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-size: 1.2rem;
  opacity: 0.5;
  right: 7px;
  text-align: 20px;
  top: 2px;
}

.ql-snow .ql-editor h2 {
  font-size: 1.5em;
  line-height: 28px !important;
}

.p-dialog .p-dialog-header-icon {
  display: none;
}

::placeholder {
  color: #acacac !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.form-control-new::placeholder {
  color: #acacac !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #acacac !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.p-datatable .p-datatable-thead>tr>th {
  font-weight: 600 !important;
  font-size: 14px !important;

}

.p-inputtext::placeholder {
  color: #acacac !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.MuiInputBase-input.MuiInput-input::placeholder {
  color: #252525 !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !;
}

.form-group.has-search {
  position: relative;
  margin-bottom: 0px;
}

.has-search .form-control {
  background-color: #fff;
  position: relative;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 13px !important;
}

.col-form-label {
  font-size: 13px !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 600;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1rem !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1rem;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 16px !important;

}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 6px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: white !important;
}


.nav-tabs .nav-link {
  font-weight: 600 !important;
  font-size: 12px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 13px !important;
  list-style: 17px;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 500 !important;
  color: #302f2f !important;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

.p-dialog .p-dialog-content p {
  line-height: 25px;
}

.mobilebuttons {
  padding-right: 10px;
  padding-left: 10px;
}

.modal-content .col-md-8 {
  margin-top: 0px !important;
}

.modal-title {
  font-size: 22px !important;
  line-height: 22px !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  color: #374252 !important;
}

.form-control {

  color: #302f2f !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 13px !important;
  font-weight: 500;
  line-height: 1.5;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.css-1na68kg {
  background: white;
}

.css-1na68kg::placeholder {
  color: #252525 !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !important;

}

.user-popup-header {
  padding: 10px 20px 0px;
}

.popup-title h1 {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 10px;
}

.popup-title h6 {
  font-size: 14px;
  line-height: 17px;
  color: #0069d9;
}

.savebtn-loading {
  position: absolute;
  top: 6px;
  left: 10px;
}

.savebtn-loading .spinner-border {
  width: 12px;
  height: 12px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
}

.Create-user-cancle-btn.user-info-cancel {
  margin-left: 10px;
}

.userinfo-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.btn-section {
  display: flex;
  flex-direction: row;
}

.sccuessmessage {
  font-size: 12px;
  line-height: 17px;
  color: #077446;

}

.css-1na68kg.MuiOutlinedInput-root.MuiAutocomplete-input::placeholder {
  color: #252525 !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.userevent-flex .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 10px;
}

.userevent-flex .MuiAutocomplete-hasPopupIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root,
.userevent-flex .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding-right: 0px !important;
  padding: 0px 10px !important;
  height: 35px;

}

.userevent-flex .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 10px;
}

.userevent-flex .MuiAutocomplete-hasPopupIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root,
.userevent-flex .MuiAutocomplete-hasClearIcon.css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root,
.userevent-flex .MuiOutlinedInput-root,
.userevent-flex .css-1na68kg .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-right: 0px !important;
  padding: 0px 9px !important;
  height: 35px;

}

.userevent-flex .MuiOutlinedInput-notchedOutline {
  position: relative;
  inset: 0px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  overflow: hidden;
  border: none;
}

.MuiAutocomplete-root {
  border: 1px solid #ced4da;
  border-radius: 6px;
}

input:focus,
input:focus-visible {
  outline: none !important;
}

.css-1na68kg .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: #302f2f !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: 'Open Sans', sans-serif;

}

.p-button.p-component.p-confirm-dialog-accept.p-button {
  background: #fc9005 !important;
  border: 1px solid #fc9005 !important;
}

.p-button.p-component.p-confirm-dialog-reject.p-button-text.p-button-text {
  background-color: transparent;
  color: #fc9005 !important;
}

.p-button.p-component.p-confirm-dialog-reject.p-button-text.p-button-text:hover {
  color: white !important;
}

.create-event-wrapper b {

  color: #595959 !important;
}

.dashboard-top-event-img {
  max-height: 221px !important;
  margin-bottom: 15px !important;
  width: 100% !important;
  background-size: cover !important;
  object-fit: contain !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-uploadfile {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 16px;
  padding-bottom: 14px;
  width: auto;
  max-width: 146px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.errorpopup {
  min-width: 20%;
  max-width: 80%;
}

.errorpopup.p-dialog .p-dialog-header {
  padding: 1.5rem 1rem 1rem;

}

.errorpopup.delete-popup {
  width: 600px;
  min-width: 30%;
  position: fixed;
}

.errorpopup.delete-popup .p-dialog-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

input::file-selector-button {
  background: #dee5f5;
  color: #090909;
  border-radius: 12px;
  width: 100px;
  height: 30px;
  border: 1px solid #dee5f5;
  font-weight: 500;
  font-size: 13px !important;
  letter-spacing: normal !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}

.event-table {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  height: auto;
  min-height: 780px;
  position: relative;
}

.dashboard .dash-top-event {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  min-height: 780px;
}

.table-loading .spinner-border {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  vertical-align: text-bottom;
  border: 2px solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.table-loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.table-loading b {
  font-size: 14px;
}

.p-button:focus {
  box-shadow: none;
}

.box-body .p-inputtext {
  padding: 7px 30px;
}

.box-body .p-input-icon-right .pi-search {
  left: 30px;
  width: 20px;
}

.button-tabs .marginL-5 {
  margin-left: 5px;
}

.pointer {
  cursor: pointer;
}

label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

.dashboardtable {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.dashboard-header-nav {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 10px !important;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
}

input.middle:focus {
  outline-width: 0;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px !important;
}



.page-body .logo {
  display: flex;
}


.about-detail-card .about-detail-left {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: auto;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.about-detail-card .about-detail-left img {
  width: 40px;
  height: 40px;
}

.about-detail-card .about-detail-right {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  padding-left: 15px;
  border-left: 1px solid #ffffff70;
}

img {
  vertical-align: middle;
  border-style: none;
}

.about-detail-card .about-detail-right h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  color: #f8f8fb;
}

.about-detail-card {
  display: flex;
  height: 125px;
  border-radius: 9px;
  padding: 20px 15px 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.about-detail-left {
  display: INLINE-BLOCK !important;
}

.about-detail-right {
  display: INLINE-BLOCK !important;
}

.about-detail-left {
  display: FLEX !important;
  align-items: CENTER;
  justify-content: CENTER;
}

.recent-profile-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-recent-event {
  background: #dae2ee 0% 0% no-repeat padding-box;
  border-radius: 22px;
  width: 200px;
  max-width: 100%;
  text-align: center;
}

.bttn-group-space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.recent-profile-wrap h2 {
  border-right: 1px solid #9ca2b1;
}

.bttn-group-space {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dash-top-event {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 14px #0000000a;
  border-radius: 2px;
  opacity: 1;
}


.dashboard-card-outline {
  border-bottom: 2px solid #e6e6e6;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.add-user-btn {
  background: #fc9005;
  color: white;
  border: none;
  border-radius: 20px;
  width: 110px;
  height: 30px;
  line-height: 18px;
}

.view-events-btn {
  background: #fc9005;
  color: white;
  border: none;
  border-radius: 20px;
  width: 150px;
  height: 30px;
  line-height: 18px;
}

.dash-refresh-btn {
  position: relative;
  background: #dee5f5;
  color: black;
  border: none;
  border-radius: 20px;
  width: 100px;
  height: 30px;
  line-height: 18px;
}

.dash-card-follow {
  background: #fc9005;
  color: #fef3e5;
  border-radius: 15px;
  max-width: 100%;
}

.text-orange {
  color: #fc9005;
}

.font-semibold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}


footer.main-footer {
  color: black;
}

.carousel-demo .product-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
  width: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.carousel-demo .product-item .product-item-content {
  border: 1px solid var(--surface-d);
  border-radius: 3px;
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
}

.carousel-demo .product-item .product-image {
  width: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-datatable-wrapper table {
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.padd-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.padd-table .p-datatable-tbody>tr>td {
  padding: 1.4rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  color: #7191be;
}

.has-search .form-control {
  border: 1px solid #dae2ee;
  background-color: #dae2ee;
  border-radius: 7px;
  padding-left: 30px;
}

/********Header**********/
.box-shadow-header {
  box-shadow: 0px 2px 6px #00000029;
}

.mobile-profile-icon svg {
  color: #889cb2;
}

.has-search .form-control::placeholder {
  color: #7191BE;
}

.card-body.card-body-mobile {
  padding: 20px;
}


.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0062cc !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0069d9 !important;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0069d9 !important;
}

.p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0069d9 !important;
}

.table> :not(:first-child) {
  border-top: none;
}

.Create-user-cancle-btn.button-width {
  border-radius: 15px;
  width: 162px;
  height: 36px;
}

.toggleNav .toggle-icon span {
  background-color: #fc9005;
}

.p-datatable-tbody tr td {
  cursor: auto;
}

.btn-primary.btn-sign-in.focus,
.btn-primary.btn-sign-in:focus {
  color: #fff !important;
  background-color: #fc9005 !important;
  border-color: #fc9005 !important;
}

.btn-primary.add-event-btn.focus,
.btn-primary.add-event-btn:focus {
  color: #fff !important;
  background-color: #fc9005 !important;
  border-color: #fc9005 !important;
}

.download-btn {
  background-color: #dee5f5;
  border-color: #dee5f5;
  border-radius: 21px;
  color: #000;
  width: 156px;
}

.clear-btn {
  background-color: #dee5f5;
  border-color: #dee5f5;
  border-radius: 21px;
  color: #000;
  width: 50%;
  font-size: 13px !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

.download-btn:active {
  color: #000 !important;
  background-color: #dee5f5 !important;
  border-color: #dee5f5 !important;
}

.download-btn:focus {
  color: #fff !important;
  background-color: #dee5f5 !important;
  border-color: #dee5f5 !important;
}

.download-btn:hover {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0062cc !important;
}

.clear-btn:hover {
  color: #fff !important;
  background-color: #0069d9 !important;
  border-color: #0062cc !important;
}

.photogallery-check input {
  height: 20px;
  width: 20px;
}

.approve-btn:hover {
  background-color: #0069d9;
  color: #fff;
  border-color: #0069d9;
}

.reject-btn:hover {
  background-color: #0069d9 !important;
  color: #fff;
  border-color: #0069d9;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #fc9005 !important;
  color: #fff !important;
  border-color: #fc9005 !important;
}

/* .btn:hover {
  background-color: #0069d9;
  color: #fff;
  border-color: #0069d9;
} */

.dashboard-top-event-img {
  height: 221px;
  width: 100%;
  margin-bottom: 15px;
}

.view-more-card {
  height: 93%;
  max-height: 230px;
}

.user-header-img {
  display: flex;
  height: 25px;
  width: 25px;
  margin-right: 5px;
  border-radius: 50%;
  box-shadow: 0px 2px 6px #0000003d;
}

.like-img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0px 2px 6px #0000007d;
}



.like-counter {
  position: absolute;
  top: 0px;
  right: -2px;
}

.edit-user-profile {
  width: 100px;
  height: 100px;

  color: #fff;
  border-radius: 50%;
  box-shadow: 0px 2px 6px #0000003d;
  margin-bottom: 20px;
}

.my-profile-user-info .profile-img {
  box-shadow: 0px 2px 6px #00000059;
}

.dashboard-card .card {
  border-radius: 0px;
}

.form-control::placeholder {
  color: #6c757d45;
  opacity: 1;
}

.textarea-size {
  height: 400px !important;
  resize: none;
  overflow: scroll;
}

.send-email-main {
  padding: 0px !important;
}

.send-email-wrappper {
  width: 100%;
}

.event-modal .modal-dialog {
  max-width: 1240px;
}

.send-email-popup {
  border: none !important;
}

.envelope-border {
  border-radius: 6px !important;
}

.emailsendbtn {
  margin-left: 1rem !important;
}

/* @media (min-width: 1400px){
  .modal-large-popup {
    max-width: 1870px;
}
.each-pop-slider{
  height: 921px;
}
.popupmain-slider .react-slideshow-container{
  height: 921px;
}
.each-pop-slider>.sliderpopup>.popup-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 1296px;
  width: 100%;
  position: relative;
  top:125px;
}
.each-pop-slidere>.sliderpopup {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100%;
}

} */
.modal-fullscreen {
  width: 99vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 !important;
}

.popupmain-slider .react-slideshow-container {
  box-shadow: none !important;
  border-radius: 0px !important;
}


.sliderpopup {
  height: auto !important;
}

.editor .rdw-editor-wrapper {
  height: auto;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.editor .rdw-editor-main {
  height: auto;
  padding: 5px 10px 5px 10px;
}

.fullscreen-btn {
  position: absolute;
  z-index: 1;
  right: 6px;
  top: 6px;
}

.fullscreen-btn button {
  background-color: transparent;
  border: none;
  color: #fff;
}

.fullscreen-btn button i {
  font-size: 2rem;
  box-shadow: 0px 4px 6px #000;
  border-radius: 50%;
  color: #fc9005;
}

.fullscreen-btn button i:hover {
  color: blue;
}

.gallery-title {
  position: relative;
  bottom: 0px;
  width: 100%;
  align-items: center;
  background-color: #242e3d;
  margin-left: 0px;
  margin-right: 0px;
  padding: 6px 0px;
}

.react-tagsinput-tag {
  border-radius: 25px !important;
  background-color: #fc9005 !important;
  border-radius: 2px;
  border: 1px solid #d19547 !important;
  color: #fff !important;
}

.react-tagsinput-input {
  width: 300px !important;
}

.react-tagsinput {
  border-radius: 0.25rem;
}

.react-tagsinput--focused {
  outline: 0;
  box-shadow: none;
  border: 1px solid #ccc;
}

.subject-shadow:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #ccc;
}

.play-photogallery {
  width: 100px;
  padding: 8px;
  font-size: 15px;
  height: 30px;
}


@media screen and (max-width: 768px) {
  .user-table table {
    width: 100%;
    overflow-x: scroll;
  }

  .play-photogallery {

    font-size: 13px;
    font-weight: 300;

  }

  .each-slide.each-pop-slider img {
    height: auto;
    width: 100%;
  }
}



.SearchButtonEventPage .btn {
  height: 35px;
  width: 150px !important;
}

.table-responsive table {
  /* display: block; */
  overflow-x: auto;
}

.search-form-user.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

.search-form-user.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: #000;
}

.alertMessage .alert {
  width: max-content;
}

.print-barcode {
  width: 2rem !important;
  height: 20px;
}

/* .barcode-popup{
  display: none;
} */
.qrcodemargin {
  margin-top: 1px !important;
  margin-left: 30px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

.css-hdw1oc {
  float: left !important;
  display: none;
}

.css-1lwbda4-MuiStack-root {
  margin-top: 4px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 30px !important;
  padding: 0px !important;
}

.userevent-flex {
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) and (min-width: 320px) {
  .alertMessage .alert {
    width: 100%;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 2000px) and (min-width: 1201px) {
  .alertMessage .alert {
    width: -webkit-max-content;
    width: max-content;
  }

  .mobile-center-exportbutton {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) and (min-width: 320px) {


  .p-paginator .p-paginator-first,
  .p-paginator .p-paginator-prev,
  .p-paginator .p-paginator-next,
  .p-paginator .p-paginator-last {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2rem;
    height: 2rem;
    margin: 0;
    transition: box-shadow 0.2s;
    border-radius: 50%;
  }

  .p-paginator .p-paginator-pages .p-paginator-page {
    background-color: transparent;
    border: 0 none;
    color: #6c757d;
    min-width: 2rem;
    height: 2rem;
    margin: 0.143rem;
    transition: box-shadow 0.2s;
    border-radius: 50%;
  }

  .p-paginator.p-component.p-paginator-bottom {
    margin: 0px;
    padding: 0px !important;
    /* font-size: 2px !important; */
    min-width: 2rem !important;
    min-height: 1px !important;
    margin-left: -10px;
  }
}

@media (max-width: 767px) and (min-width: 320px) {
  .userevent-flex {
    display: block;
  }

  .mobile-center {
    justify-content: center;
  }

  .mobile-center-exportbutton {
    justify-content: center;
    margin-top: 10px;
  }

  .mobile-center-playbutton {
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }

  span.p-column-title {
    white-space: nowrap;
  }

  h1#dashboard {
    font-size: 16px;
  }
}

form label {
  color: #302f2f !important;
}

.rdw-image-imagewrapper img {
  max-width: 100%;
}



.box-body .p-datatable table {
  table-layout: inherit !important;
}

.user-table table {
  white-space: nowrap;
}

.bg-white-table {
  background-color: #ffff;
  padding: 0px;
}

.Recent-event {
  flex: 0 0 70%;
}

.refresh-btn-wrapper {
  flex: 0 0 30%;
}

.add-event-btn.create-event-btn {
  width: 110px;
  position: relative;
}

.add-event-btn.create-event-btn img {
  position: absolute;
  left: 9px;
  top: 10px;
}


.changepasswordbtn {
  justify-content: center;
  margin-top: 20px;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl ::placeholder {
  color: #1f1f1f !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  /* -webkit-text-fill-color: #090909; */
  -webkit-box-shadow: 0 0 0px 40rem #ffff inset;
}

.ql-snow .ql-editor h2 {
  height: 220px;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  color: #302f2f !important;
  font-size: 13px !important;
  font-weight: 600;
  line-height: 1.5;
  background-color: #fff !important;
  border-radius: 6px;
}

.p-component {
  font-family: 'Open Sans', sans-serif;
}

.btnplay {
  padding: 5px 35px;
}

.logo-fit.participic-logo {
  width: 130px;
  height: 45px;
  margin-bottom: 20px;
}

.create-event-wrapper .userevent-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.p-paginator-pages {
  display: flex;
  flex-direction: row;
}

#SendMailButton,
#SendNotificationButton {
  display: flex;
}

.logo-wrapper a.logo img {
  width: 150px;
  height: 40px;
  margin-top: 30px;
  margin-left: -10px;
}


.edit-profile-mobile-view {
  flex-direction: column-reverse;
}

.choose-file {
  display: flex;
  justify-content: center;
}

.searchWrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 6px;
  position: relative;
}

.add-user-form .row {
  margin-bottom: 10px;
}

.userevent-flex.user-info-details {
  flex-direction: column;
}

.userevent-flex.user-info-details .align-items-center {
  width: 100%;
  justify-content: flex-start;
  margin: 0px;
}

.userevent-flex.user-info-details .MuiAutocomplete-root {
  width: 100%;
}

#search_input::placeholder {
  opacity: 1 !important;
  color: #979797 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif;
}

.disable_ms {
  pointer-events: none;
  opacity: 1.0 !important;
}

.form-label {
  font-size: 14px !important;
  font-family: 'Open Sans', sans-serif !important;
  color: black;
  font-weight: 600;
}

.MuiInputLabel-root {
  color: #909090d6 !important;
  background-color: #fff;
  font-weight: 300 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.file-loading {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.file-loading input {
  width: 185px;
}

.p-dialog .p-dialog-footer,
.p-dialog .p-dialog-content {
  padding: 15px !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.eyeicondashboard {
  height: 15px;
  margin-right: 5px;
}

.font-500 {
  font-weight: 500;
}

a:hover {
  text-decoration: none !important;
}

.slick-slider,
.slick-list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.each-slide.each-pop-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider .slick-list {
  display: block;
  width: 100%;
}

.popup-error-msg {
  width: 100%;
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.userevent-flex.createeventmanagepage {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 15px 20px 10px;
  background-color: white;
  border-radius: 10px
}

.p-dialog-header {
  padding: 20px 20px 20px 20px !important;
}

.event-management-btn-row .createeventmng {
  display: flex;
  justify-content: end;
}

.dashboard-page {
  padding: 0px !important;
}

.image-footer {
  display: flex;
  justify-content: space-between;
}

.gray-input {
  background-color: rgb(241 241 241) !important
}

.iconsize {
  font-size: 12px !important;
}

.pending-for-approval {
  display: flex;
}

.like-button {
  position: relative;
}

.page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.page-not-found h1 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
}

.profile-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.like-img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0px 2px 6px #0000007d;
}

.dropdown-menu-new.show.test {
  padding-right: 10px;
  padding-left: 10px;
}

.cover-photo-text {
  font-size: 12px;
  color: #fbff00;
  font-weight: 500;
}

.modal-backdrop .datepicker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.show {
  opacity: 0;
}

.p-datepicker.p-component.p-connected-overlay-enter-done {
  z-index: 1111 !important;
}

.react-date-picker__calendar {
  z-index: 1111 !important;
}
.react-datepicker-popper{
    z-index: 1111 !important;
}
.react-datepicker-wrapper{
  display: block !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  display: none !important;
}
.react-datepicker-popper .react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  width: 300px;
  color: #000;
  border: none;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.react-datepicker .react-datepicker__month-container{
  width: 300px;
  border: none;
}
.react-datepicker .react-datepicker__header{
background-color: #3f51b5;
color: #fff;
}
 .react-datepicker .react-datepicker__month-dropdown , .react-datepicker .react-datepicker__year-dropdown{
  background-color: #fff;

  color: #000;
  font-size: 14px;
  font-weight: 500;
  
  
}

 .react-datepicker .react-datepicker__month-option--selected_month, .react-datepicker .react-datepicker__year-option--selected_year{
  color: #3f51b5;
    font-weight: 600;
}
.react-datepicker__month-option , .react-datepicker__year-option{
  font-size: 16px;
  line-height: 27px !important;
      font-weight: 500;
}

.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker .react-datepicker__month-read-view--down-arrow,
.react-datepicker .react-datepicker__month-year-read-view--down-arrow,
.react-datepicker .react-datepicker__navigation-icon::before{
    border-color: #fff;
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: "";
      display: block;
      height: 9px;
      position: absolute;
  
}

.react-datepicker .react-datepicker__month-year-read-view--down-arrow,.react-datepicker .react-datepicker__month-read-view--down-arrow{
top: 2px;
}
.react-datepicker .react-datepicker__day-name{
color: #fff;
line-height: 2.9rem;
width: 2.1rem;
}

.react-datepicker .react-datepicker__day{
line-height: 2.5rem;
width: 2.1rem;
}
.react-datepicker .react-datepicker__day--keyboard-selected,.react-datepicker .react-datepicker__day--selected{
background-color: #3f51b5;
color: #fff;
border-radius: 0px;
}
.react-datepicker .react-datepicker__day--keyboard-selected:hover{

  background-color: #3f51b5;
  border-radius: 0px;
}

.react-datepicker .react-datepicker__month-option:hover,
.react-datepicker .react-datepicker__month-year-option:hover,.react-datepicker .react-datepicker__year-option:hover{
  background-color: #3f51b5;
  color: #fff;
}