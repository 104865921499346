html {
  overflow-x: hidden;
}


ul.header-top-right-menus {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-slider {
  background: #bac7db;
  /* width: 1122px; */
  height: 260px;
  box-shadow: 0px 6px 12px #8351e14d;
  border-radius: 2px;
  opacity: 1;
  max-width: 100%;
}

