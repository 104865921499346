.successdialog .p-dialog-header .p-dialog-title {
    font-weight: 700 !important;
    font-size: 30px !important;
}

.successdialog .p-dialog-header {
    text-align: center !important;
}

.fontSize {
    font-size: 24px !important;
}